<template>
  <CyMarkdownCard :source-text="privacyPolicy"/>
</template>

<script>
import CyMarkdownCard from '@/components/markdown-card.vue'
import { privacyPolicy } from '@/utils/markdown'

export default {
  name: 'CyPagePrivacyPolicy',
  components: { CyMarkdownCard },
  data: () => ({
    privacyPolicy,
  }),
  i18n: {
    messages: {
      en: {
        title: '@:routes.privacy',
      },
      es: {
        title: '@:routes.privacy',
      },
      fr: {
        title: '@:routes.privacy',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep h1 + p:first-of-type {
  color: get-color("primary", "light-4");
}
</style>
